import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccounts, IAccountDetails } from "../pear-state/account_slice";
import { getFormattedAmount } from "./Helpers";
import EmptyState from "./EmptyState";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SnackbarContext from "../pear-state/snackbar_context";
import Skeleton from "./interface/Skeleton";
import { deleteAccount } from "../pear-state/account_slice";
import { AppDispatch, RootState } from "pear-state/store";

const Accounts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const accountsStatus = useSelector((state: RootState) => state.accounts.status);
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);

  useEffect(() => {
    if (accountsStatus === "idle") {
      dispatch(fetchAccounts());
    }
  }, [accounts, dispatch]);

  if (accountsStatus === "succeeded" && accounts.length === 0) {
    return <EmptyState />;
  }

  if (accountsStatus === "loading") {
    return <Skeleton />;
  }

  const handleDelete = (account_uuid: string) => {
    if (confirm("Do you want to delete this account?")) {
      dispatch(deleteAccount(account_uuid)).then(() => {
        snackbarCtx.displayMessage("Account deleted", "bg-pear-opal");
        dispatch(fetchAccounts()).then(() => navigate("/accounts"));
      });
    }
  };

  return (
    <div className="w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
          >
            <a href="/create-account">Add account</a>
          </button>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-200">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Balance
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Currency
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {accounts &&
                    accounts.map((account: IAccountDetails, accountIdx: number) => (
                      <tr key={account.uuid} className={accountIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          <a href={`/accounts/${account.uuid}`} className="text-pear-purple hover:text-indigo-600">
                            {account.name}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {account.currency === "CAD"
                            ? getFormattedAmount(account.balance?.combined_cad as number, account.currency)
                            : getFormattedAmount(account.balance?.combined_usd as number, account.currency)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.account_type}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.currency}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-row gap-2">
                            <a href={`/accounts/${account.uuid}/edit`} className="text-gray-600 hover:text-black">
                              <PencilSquareIcon className="h-5 w-5" />
                            </a>
                            <button
                              onClick={() => handleDelete(account.uuid as string)}
                              className="text-gray-600 hover:text-black"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
